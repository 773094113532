<template>
  <CardToolbar
    @days-changed="changeDayValue"
    description="The distribution of the Artist's monthly listeners across countries"
    :show-toolbar-buttons="true"
    title="New Spotify followers"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" animated />
      <el-row v-else :gutter="16">
        <el-col :span="24">
          <div class="mb-5 mb-xl-10 border p-2">
            <apexchart
              ref="newSpotifyFollowers"
              type="bar"
              v-loading="loading"
              height="350"
              :series="data"
              :options="chartOptions"
            ></apexchart>
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getFanbaseGrowth } from "@/api/artists.api";

import CardToolbar from "../../common/components/CardToolbar";
export default {
  name: "NewSpotifyFollowers",
  components: { CardToolbar },
  setup() {
    const loading = ref(false);
    const days = ref(365);
    const chartOptions = ref({});
    const data = ref([]);
    const route = useRoute();
    onMounted(async () => {
      loading.value = true;
      const response = await getFanbaseGrowth(
        route.params.uuid,
        "spotify",
        days.value,
        true
      );
      data.value = [
        {
          name: "Followers",
          data: response.data.yAxis,
        },
      ];
      chartOptions.value = {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            colors: {
              ranges: [
                {
                  from: -100000000000,
                  to: 0,
                  color: "#F15B46",
                },
                {
                  from: 0,
                  to: 100000000000,
                  color: "#48B955",
                },
              ],
            },
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.8,
        },
        grid: {
          show: true,
        },
        xaxis: {
          categories: response.data.xAxis,
          labels: {
            rotate: -90,
            rotateAlways: true,
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return Number(value).toLocaleString();
            },
          },
        },
      };
      loading.value = false;
    });

    return {
      loading,
      days,
      data,
      chartOptions,
    };
  },
  methods: {
    async changeDayValue(day) {
      if (this.days !== day) {
        this.loading = true;
        this.days = day;
        const isYearData = day === 365;
        const response = await getFanbaseGrowth(
          this.$route.params.uuid,
          "spotify",
          this.days,
          isYearData
        );
        this.chartOptions.xaxis.categories = response.data.xAxis;
        this.data[0].data = response.data.yAxis;
        if (this.$refs.newSpotifyFollowers) {
          this.$refs.newSpotifyFollowers.updateOptions(this.chartOptions);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="card h-100">
    <div
      class="
        card-body
        py-3
        d-flex
        flex-column
        align-items-end
        justify-content-end
      "
    >
      <table class="table table-responsive table-row-gray-300 gy-7">
        <thead>
          <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
            <th c v-for="(header, index) in tableHeaders" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(item, index) in tableData"
            :key="index"
            class="d-flex"
          >
            <tr>
              <td class="table-item">
                <div class="d-flex align-items-center">
                  <el-avatar :size="35" :src="item.imageUrl"></el-avatar>
                  <div class="song-name">{{ item.name }}</div>
                </div>
              </td>

              <td>
                <div class="symbol-group symbol-hover">
                  <div
                    v-for="(artist, index) in item.artists"
                    :key="index"
                    class="symbol symbol-35px symbol-circle"
                    data-bs-toggle="tooltip"
                    :title="artist.name"
                  >
                    <img
                      v-if="artist.imageUrl"
                      alt="Pic"
                      :src="artist.imageUrl"
                      @click="openArtistProfileDrawer(artist.uuid)"
                    />
                    <span
                      v-else
                      class="symbol-label fw-bolder"
                      :class="`bg-primary text-inverse-primary`"
                      @click="openArtistProfileDrawer(artist.uuid)"
                      >{{ artist.name.charAt(0) }}</span
                    >
                  </div>
                </div>
              </td>

              <td>
                {{
                  item.totalStreams ? formatNumber(item.totalStreams) : "N/A"
                }}
              </td>
              <td>{{ new Date(item.releaseDate).toDateString() }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useNumberFormatter } from "@/common/composables/useNumberFormatter";

export default {
  name: "Top10TracksTable",
  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { formatNumber } = useNumberFormatter();
    return { formatNumber };
  },
};
</script>

<style scoped>
.table.gy-7 th,
.table.gy-7 td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.song-name {
  margin: 5px;
}
</style>

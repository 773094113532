<template>
  <CardToolbar
    description="Artist's top 10 tracks"
    title="Top 10 Tracks on Spotify"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <Top10TracksTable
        v-if="tableData.length"
        :table-headers="tableHeaders"
        :table-data="tableData"
      />
      <el-skeleton v-else :rows="10" animated />
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import CardToolbar from "../../common/components/CardToolbar";
import Top10TracksTable from "./Top10TracksTable";

import { getSpotifyTopTenTracks } from "@/api/artists.api";

export default {
  name: "Top10Tracks",
  components: { CardToolbar, Top10TracksTable },

  setup() {
    const route = useRoute();
    const tableData = ref([]);
    const loading = ref(false);
    onMounted(async () => {
      try {
        loading.value = true;
        let { data } = await getSpotifyTopTenTracks(route.params.uuid);
        data = data.data;
        tableData.value = data.sort((a, b) => {
          return a.totalStreams < b.totalStreams ? 1 : -1;
        });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    const tableHeaders = ["Track", "Artist", "Total Streams", "Release Date"];

    return { tableHeaders, tableData };
  },
};
</script>

<style scoped></style>

<template>
  <CardToolbar
    @days-changed="changeDayValue"
    description="The distribution of the Artist's monthly listeners across countries"
    :show-toolbar-buttons="true"
    title="Spotify Popularity"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" animated />
      <el-row v-else :gutter="16">
        <el-col :span="24">
          <div class="mb-5 mb-xl-10 border p-2">
            <apexchart
              ref="spotifyPopularity"
              type="area"
              v-loading="loading"
              height="350"
              :series="data"
              :options="chartOptions"
            ></apexchart>
          </div>
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { getSpotifyPopularity } from "@/api/artists.api";
import { getCSSVariableValue } from "@/assets/ts/_utils/DomHelpers.ts";

import CardToolbar from "../../common/components/CardToolbar";

export default {
  name: "SpotifyPopularity",
  components: { CardToolbar },
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const loading = ref(false);
    const days = ref(365);
    const chartOptions = ref({});
    const data = ref([]);
    const route = useRoute();
    onMounted(async () => {
      loading.value = true;
      const response = await getSpotifyPopularity(
        route.params.uuid,
        "spotify",
        days.value,
        true
      );
      data.value = [
        {
          name: "Followers",
          data: response.data.yAxis,
        },
      ];
      chartOptions.value = {
        chart: {
          fontFamily: "inherit",
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.3,
          },
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: ["#48B955"],
        },
        xaxis: {
          categories: response.data.xAxis,
          tickPlacement: "between",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: "#48B955",
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
            formatter: (value) => {
              return Number(value).toLocaleString();
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return Number(val).toLocaleString();
            },
          },
        },
        colors: ["#48B955"],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          strokeColor: "#48B955",
          strokeWidth: 3,
        },
      };
      loading.value = false;
    });

    return {
      loading,
      days,
      data,
      chartOptions,
    };
  },
  methods: {
    async changeDayValue(day) {
      if (this.days !== day) {
        this.loading = true;
        this.days = day;
        const isYearData = day === 365;
        const response = await getSpotifyPopularity(
          this.$route.params.uuid,
          "spotify",
          this.days,
          isYearData
        );
        this.chartOptions.xaxis.categories = response.data.xAxis;
        this.data[0].data = response.data.yAxis;
        if (this.$refs.spotifyPopularity) {
          this.$refs.spotifyPopularity.updateOptions(this.chartOptions);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
